@import '../mixins';

.tooltip {
  z-index: 1;
  width: 1.75rem;
  background-color: #000000cc;
  font-family: $base-font;
  font-size: 0.15rem;
  color: #fefefe;
  text-align: center;
  line-height: 1.3;
  padding: 0.1rem 0.25rem;
  border: 1px solid #aaaaaa;
}
