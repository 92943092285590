$milestone-gap: 0.455rem; // adjust to match milestone percentage

.milestone {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 115%;
  position: relative;
  align-items: flex-end;

  .bg {
    height: 100%;
    position: absolute;
    z-index: -1;
    top: -1%;
    left: -55%;
  }

  .total {
    position: relative;
    top: -2%;
    font-size: 0.12rem;
    background: var(--color-base);
    padding: 0.04rem 0.1rem 0.04rem 0.04rem;
    border-radius: 0.05rem;
    border: 0.015rem solid #6a6a6a;
    @include flexrc;
    gap: 0.05rem;

    &__txt {
      background: #191d30;
      padding: 0.02rem 0.1rem;
      border-radius: 0.05rem;
      line-height: 1.25;

      strong {
        color: var(--color-accent);
        font-family: $base-font-bold;
      }
    }

    &__amount {
      @include flexrc;
      color: #afacac;

      .ico-voucher {
        width: 0.3rem;
      }

      strong {
        color: white;
        font-size: 0.18rem;
        font-family: $base-font-bold;
      }
    }
  }

  .progress {
    height: 78%;
    position: relative;
    top: 4%;
    display: flex;
    gap: 0.2rem;
    flex-direction: row-reverse;

    .ico-milestone {
      position: absolute;
      left: 72%;
      bottom: -2%;
      width: 0.7rem;
    }

    &__bar {
      background: var(--img-slider-base);
      @include bgc;
      width: 0.4rem;
      height: 100%;
      padding: 0.05rem;
      display: flex;
      align-items: flex-end;

      &__fill {
        background: linear-gradient(to top, var(--color-base) 50%, var(--color-accent));
        width: 100%;
        max-height: 100%;
        transition: height 1s;
        transition-delay: 1s;
      }
    }

    &__rewards {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column-reverse;
      font-size: 0.2rem;
      font-family: $base-font-bold;
      position: relative;
      top: -2%;
      align-items: flex-end;

      .reward {
        position: relative;
        left: 40%;
        min-width: 1.5rem;
        white-space: nowrap;
        margin-bottom: $milestone-gap;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__target {
          @include flexrc;
          justify-content: flex-end;
          
          &__count {
            background-color: var(--color-base);
            padding: 0.05rem 0.1rem;
            font-size: 0.18rem;
            border-radius: 0.05rem;

            strong {
              color: white;
              .unit {
                color: white;
              }
            }
          }
          .bar {
            width: 0.6rem;
            height: 0.01rem;
            background-color: white;
          }
        }

        &__prize {
          display: grid;
          place-items: center;
          position: relative;
          top: 20%;
          right: 40%;

          > * {
            grid-area: 1/1/1/1;
          }

          &__count {
            position: absolute;
            top: -22%;
            font-size: 0.18rem;
            color: black;
            padding: 0.02rem 0.1rem 0.1rem 0.1rem;
            left: -70%;
            z-index: 1;

            // fliped item count bg
            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background: var(--img-ico-amount);
              @include bgc;
              position: absolute;
              left: 0%;
              top: 0%;
              transform: scaleX(-1);
            }

            span {
              position: relative;
            }
          }

          &__img {
            cursor: pointer;
            height: 0.65rem;
            filter: drop-shadow(0.01rem 0.01rem 0.05rem #222);

            &.glow {
              filter: drop-shadow(0.01rem 0.01rem 0.05rem var(--color-accent));
              animation: glowing 3s ease-out infinite;
            }

            &.greyed {
              filter: grayscale(0.1) brightness(0.5);
            }
          }

          .ico-checklist {
            height: 0.5rem;
            z-index: 1;
          }
        }
      }
    }
  }

  &.personal-milestone {
    align-items: flex-start;

    .bg {
      left: unset;
      right: -15%;
    }

    .progress {
      flex-direction: row;

      .ico-milestone {
        left: -0.15rem;
        position: absolute;
        bottom: -2%;
        width: 0.7rem;
      }

      &__rewards {
        align-items: baseline;

        .reward {
          left: -0.6rem;
          align-items: flex-start;

          &__target {
            flex-direction: row-reverse;
          }

          &__prize {
            right: unset;
            left: 40%;

            &__count {
              left: unset;
              right: -75%;
              background: var(--img-ico-amount);
              @include bgc;

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
