.wp_pop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
  overflow: hidden;

  .wp_content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .wp_pop_img {
    width: 25vw;
    height: 16vw;
    margin: auto;
    background-repeat: no-repeat;
    background-size: contain;
    animation: rotateA 1.5s ease infinite alternate;
  }

  .wp_tips {
    width: 60%;
    font-size: 2vw;
    color: #ffffff;
    text-align: center;
    margin: 6vw auto 0;
  }

  @media screen and (max-width: 1024px) and (orientation: portrait) {
    &.portrait {
      display: block;
    }
  }

  @media screen and (max-width: 1000px) and (orientation: landscape) {
    &.landscape {
      display: block;
    }
  }

  @media screen and (min-width: 820px) and (orientation: landscape) {
    &.desktop_landscape {
      display: block;
    }
  }
}

@keyframes rotateA {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
