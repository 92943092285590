button {
  @include px2res(font-size, 22);
  font-family: $base-font;
  color: white;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;

  &.disabled {
    cursor: default;
    pointer-events: none;

    &:active {
      filter: brightness(1);
    }
  }

  &:active {
    filter: brightness(1.1);
    transform: translate3d(0, 0.1vmax, 0);
  }
}
