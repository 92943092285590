@import "./variable.scss";

@import url("https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap");

@font-face {
  font-family: "GFF Latin Reg";
  src: url($CDN + "fonts/GFF-Latin-Regular.otf") format("opentype");
}

@font-face {
  font-family: "GFF Latin Bold";
  src: url($CDN + "fonts/GFF-Latin-Bold.otf") format("opentype");
}

@font-face {
  font-family: "GFF Latin Medium";
  src: url($CDN + "fonts/GFF-Latin-Medium.otf") format("opentype");
}

@font-face {
  font-family: "GFF Latin Extra Bold";
  src: url($CDN + "fonts/GFF-Latin-ExtraBold.otf") format("opentype");
}
