.donate {
  width: 50%;
  height: 100%;
  @include flexcc;

  .frame {
    position: relative;
    width: 100%;
    height: 100%;
    @include flexcc;
    background-image: var(--img-voucher-scroll);
    @include bgc;

    .desc {
      position: absolute;
      top: 2.35rem;
      @include flexcc;
      gap: 0.1rem;
      color: #010101;
      width: 80%;

      strong {
        width: 90%;
        display: inline-block;
        font-size: 0.2rem;
        font-weight: bold;
        color: var(--color-base);
        font-family: $base-font-extra-bold;
      }

      p {
        width: 100%;
        font-size: 0.16rem;
        text-align: center;
        line-height: 1.25;
      }
    }

    .btn-donate {
      background-image: var(--img-btn-donate);
      font-size: 0.2rem;
      color: white;
      @include bgc;
      padding: 0.1rem 0.5rem;
      position: absolute;
      bottom: 0.4rem;
      font-weight: 900;

      &.disabled {
        filter: grayscale(1);
        pointer-events: none;
      }
    }
  }
}
