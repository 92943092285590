.home {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2rem 0.4rem 0.3rem 0.4rem;
  animation: fadeIn 1.5s;

  main {
    width: 100%;
    height: 80%;
    @include flexrc;
    gap: 0.1rem;
  }
}
