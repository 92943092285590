@import 'variable';

#root {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
}

#Main {
  position: fixed;
  margin: auto;
  top: 0 !important;
  right: 0;
  left: 0;
  bottom: 0;
  width: 13.34rem;
  height: 6.6rem;

  &:before {
    content: '';
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--img-bg-voucher);
    @include bgc;
    background-size: cover;
  }

  .content-container {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: $base-font;
    font-size: 0.21rem;
    color: #fefefe;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
  }
}
