@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 175%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(50%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 175%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes shadow {
  from {
    box-shadow: none;
    -webkit-box-shadow: none;
    visibility: hidden;
  }
  to {
    box-shadow: 0 0 0.75vh 1vh #cda822;
    -webkit-box-shadow: 0 0 0.75vh 1vh #cda822;
    visibility: visible;
  }
}

.shadow {
  animation-name: shadow;
  animation-iteration-count: infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}

.zoomInOut {
  animation-name: zoomInOut;
}

@keyframes zoomIn {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes glowing {
  0% {
    filter: drop-shadow(0.01rem 0.01rem 0.05rem var(--color-accent));
  }
  50% {
    filter: drop-shadow(0.01rem 0.01rem 0.1rem var(--color-accent));
  }
  100% {
    filter: drop-shadow(0.01rem 0.01rem 0.05rem var(--color-accent));
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation-name: rotating;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: scale(1, 1) rotate(0deg);
  }
  33% {
    transform: scale(1.2, 1.2) rotate(-0deg);
  }
  66% {
    transform: scale(1.2, 1.2) rotate(0deg);
  }
  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fast {
  animation-duration: 500ms;
}

.faster {
  animation-duration: 250ms;
}

.infinite {
  animation-iteration-count: infinite;
}
