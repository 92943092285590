@import 'reset';
@import 'animation';
@import 'font';
@import 'functions';
@import 'mixins';
@import 'scrollbar';
@import 'layout';
@import 'variable';

@import 'pages/home';

@import 'components/button';
@import 'components/header';
@import 'components/donate';
@import 'components/milestone';
@import 'components/modal';
@import 'components/tooltip';

*:not(input) {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

a {
  text-decoration: none;
}

.hide {
  opacity: 0;
}
