/* FIXED FLICKER MODAL */
.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal,
.react-responsive-modal-root {
  animation-fill-mode: forwards !important;
  top: 0 !important;
  right: 0;
  left: 0;
  bottom: 0;
}

.react-responsive-modal-root {
  display: flex;
  align-items: center;
}

.react-responsive-modal-overlay {
  background: #000000dc !important;
  // backdrop-filter: blur(0.1rem);
  overflow: hidden !important;

  &.overlay-toast {
    background: #00000080 !important;
  }
}

.react-responsive-modal-container {
  width: 13.34rem !important;
  height: 6.6rem !important;
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: hidden !important;
}

.react-responsive-modal-modal {
  padding: 0.1rem 0.1rem 0.1rem 0.1rem !important;
  margin: 0 auto !important;
  max-width: none !important;
  box-shadow: none !important;
}

.modal {
  position: relative;
  @include flexcc;
  background: var(--img-bg-popup) 100%/100% no-repeat !important;
  font-family: $base-font;
  font-size: 0.2rem;
  color: #fefefe;
  overflow: hidden;

  &.modal-confirm,
  &.modal-congrat,
  &.modal-thankyou {
    .btn-close {
      top: 0.52rem;
    }
  }

  &.popup-big {
    width: 11rem;
    height: 5.5rem;
    border-radius: 0.25rem;
    border: 0.01rem solid #707070;
  }

  &.popup-medium {
    width: 10rem;
    height: 5rem;
    background: transparent !important;
  }

  &.toast {
    width: 8rem;
    height: 1.1rem;
    background: transparent !important;
  }

  .btn-close {
    position: absolute;
    top: 0.4rem;
    right: 0.48rem;
    z-index: 1;

    .ico-close {
      width: 0.35rem;
      height: 0.35rem;
      background-image: var(--img-ico-close);
      @include bgc;
    }
  }

  .modal__container {
    width: 100%;
    height: 100%;

    .modal__title {
      width: 100%;
      height: 17.5%;
      color: var(--color-accent);
      font-family: $base-font-bold;
      font-size: 0.32rem;
      @include flexcc;

      &.info, &.history {
        border-bottom: 0.02rem solid white;
        width: 80%;
        margin-left: 10%;
      }
      
      &.confirm,
      &.thankyou,
      &.congrat {
        position: relative;
        top: 0.25rem;
        height: 12.5%;
        z-index: 1;
        font-size: 0.48rem;
      }
    }

    .modal__content {
      width: 100%;
      height: 82.5%;
      padding: 0.2rem;

      // MODAL TOAST
      &.toast {
        width: 100%;
        height: 100%;
        @include flexcc;
        background: transparent
          linear-gradient(270deg, #3f3f3f00 0%, #030303 50%, #3f3f3f00 100%) 0%
          0% no-repeat padding-box !important;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(
            to left,
            #00000000 1%,
            #ffffff 50%,
            #00000000 100%
          )
          100% 0 100% 0/1px 0 1px 0 stretch;

        p {
          font-size: 0.3rem;
          font-style: italic;
          text-transform: uppercase;
          margin-bottom: 0.05rem;
          color: var(--color-accent);
        }
      }

      // MODAL HISTORY
      &.history {
        padding: 0.2rem 0.5rem 0.2rem 1.25rem;

        .date {
          width: 27.5%;
        }

        .type {
          width: 22.5%;
        }

        .item {
          width: 45%;
        }

        .thead {
          @include flexrc;
          font-family: $base-font-bold;
          font-size: 0.25rem;
          color: white;
          margin-bottom: 0.275rem;
        }

        .tbody {
          width: 100%;
          height: 82.5%;
          overflow: auto;
          color: #bcbcbc;

          .list {
            @include flexrc;
            margin-bottom: 0.2rem;

            .type,
            .item {
              text-transform: capitalize;

              &.highlight {
                color: var(--color-accent);
              }
            }
          }
        }
      }

      // MODAL INFO
      &.info {
        padding: 0.2rem 0.5rem 0.2rem 1.25rem;

        .event-info {
          width: 100%;
          height: 80%;
          line-height: 1.5;
          padding-right: 0.5rem;
          overflow-y: auto;

          p {
            margin-bottom: 1%;
            padding-left: 1%;
          }

          ol {
            list-style-type: decimal;
            padding-left: 4%;

            li {
              padding-left: 1.5%;
              margin-bottom: 1%;
            }
          }
        }

        .support-wrapper {
          position: relative;
          z-index: 0;
          width: 100%;
          height: 20%;
          @include flexcc;
          padding-top: 4%;
          padding-right: 5%;
        }
      }

      // MODAL CONFIRM
      &.confirm {
        @include flexcc;
        padding: 0.2rem 0.5rem 0.2rem 1rem;

        .header {
          @include flexrc;
          position: relative;
          bottom: 20%;
          font-size: 0.25rem;

          .amount {
            @include flexrc;
            background: var(--color-base);
            border: 0.02rem solid #504c4c;
            border-radius: 0.15rem;
            padding: 0.05rem 0.1rem;
            margin-left: 0.2rem;

            .ico-voucher {
              width: 0.3rem;
              margin-top: 0.04rem;
              margin-right: 0.05rem;
            }

            span {
              font-family: $base-font-medium;
              font-size: 0.25rem;
            }
          }
        }

        .desc {
          position: absolute;
          top: 33.5%;
          font-size: 0.225rem;
          color: var(--color-accent);
        }

        .value {
          font-family: $base-font-bold;
          font-size: 0.45rem;
          color: var(--color-accent);
        }

        .slider {
          @include flexrc;
          font-family: $base-font-medium;
          font-size: 0.3rem;
          margin-top: 0.1rem;

          span {
            &:nth-child(1) {
              margin-right: 0.1rem;
            }
            &:nth-child(3) {
              margin-left: 0.1rem;
            }
          }

          input {
            width: 4rem;
            height: 0.1rem;
            background: #504c4c;
            border-radius: 0.1rem;
            outline: none;
            opacity: 1;
            appearance: none;
            -webkit-appearance: none;

            &::-webkit-slider-thumb {
              width: 0.3rem;
              height: 0.3rem;
              background: white;
              border-radius: 50%;
              cursor: pointer;
              -webkit-appearance: none;
              appearance: none;
            }
          }

          .ico-voucher {
            width: 0.3rem;
            height: 0.3rem;
            background-image: var(--img-ico-voucher);
            @include bgc;
          }
        }

        button {
          position: relative;
          top: 20%;
          font-family: $base-font-bold;
          background: var(--img-btn-donate);
          @include bgc;
          color: white;
          padding: 0.15rem 1rem;
          border-radius: 1rem;
        }
      }

      // MODAL THANKYOU
      &.thankyou {
        padding: 0.4rem 1.5rem 0.2rem 1.5rem;
        text-align: center;
        font-size: 0.25rem;
        line-height: 1.5;
        @include flexcc;
        gap: 0.3rem;

        span {
          @include flexrc;
          color: var(--color-accent);
          font-size: 0.45rem;

          .ico-voucher {
            width: 0.7rem;
          }
        }

        button {
          font-family: $base-font-bold;
          background: var(--img-btn-donate);
          @include bgc;
          color: white;
          padding: 0.15rem 1rem;
          border-radius: 1rem;
        }
      }

      // MODAL CONGRAT
      &.congrat {
        padding: 0.4rem 1.5rem 0.2rem 1.5rem;
        text-align: center;
        font-size: 0.25rem;
        @include flexcc;
        gap: 0.3rem;

        img {
          height: 1.3rem;
        }

        .convert-item {
          font-size: 0.18rem;
          color: #bcbcbc;
        }

        button {
          font-family: $base-font-bold;
          background: var(--img-btn-donate);
          @include bgc;
        color: white;
          padding: 0.15rem 1rem;
          border-radius: 1rem;
        }
      }
    }
  }
}
