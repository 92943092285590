header {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.15rem;
  position: relative;

  .left {
    @include flexrc;

    .sfx-container {
      @include flexrc;
      margin-right: 0.15rem;
      cursor: pointer;

      &:active {
        filter: brightness(1.1);
        transform: translate3d(0, 0.1vmax, 0);
      }

      .ico-sound {
        background-image: var(--img-ico-sound-on);
        width: 0.5rem;
        height: 0.5rem;

        &.off {
          background-image: var(--img-ico-sound-off);
        }
      }

      .txt-sound {
        font-size: 0.16rem;

        &.off {
          color: #aeaeae;
        }
      }
    }

    .uid {
      color: white;
      font-size: 0.2rem;
      margin-right: 0.1rem;
      filter: drop-shadow(0 0px 0.05rem black);
      font-weight: 900;
      
      span {
        color: #fefefe;
        font-weight: 400;
      }
    }

    button {
      width: 0.4rem;
      height: 0.4rem;
      @include flexrc;
      @include bgc;
      margin-right: 0.1rem;
    }

    .ico-history {
      background-image: var(--img-ico-history);
    }

    .ico-info {
      background-image: var(--img-ico-info);
    }
  }

  .center {
    position: absolute;
    left: 3.7rem;
    top: 25%;
    @include flexcc;
    gap: 0.05rem;

    .logo-ff {
      width: 1.6rem;
    }
    .title {
      font-family: $base-font-extra-bold;
      color: white;
      font-size: 0.6rem;
      filter: drop-shadow(0 0.05rem 0.1rem #474242);
    }
  }

  .right {
    @include flexrc;

    .voucher {
      @include flexrc;
      font-family: $base-font-bold;

      span {
        filter: drop-shadow(0 0px 0.05rem black);
      }
    }

    .ico-voucher {
      width: 0.5rem;
    }
  }
}
