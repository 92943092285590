/* width */
::-webkit-scrollbar {
  @include px2res(width, 7);
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: var(--color-accent);
}

// /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  filter: grayscale(1);
}
